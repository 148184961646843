/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { AlertController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeedbackData } from '../modals/feedback/feedback.component';
import { ConferenceEvent } from '../models/conference-event.model';

export const STORAGE_REQ_KEY = 'storedreq_';
export const STORAGE_REQ_USERS = 'storedreq_user';
export const STORAGE_REQ_NODES = 'storedreq_node_application';
export const STORAGE_REQ_PAYMENTS = 'storedreq_commerce_payment_transaction';
export const STORAGE_REQ_ORDERS = 'storedreq_commerce_order';
export const STORAGE_REQ_LINE_ITEMS = 'storedreq_commerce_line_item';
export const STORAGE_REQ_CUSTOMER_PROFILE = 'storedreq_commerce_customer_profile';

export interface StoredRequest {
  url: string;
  type: string;
  data: any;
  time: number;
  reqID: string;
  id: string;
  entityType: string;
  send: boolean;
}

interface SyncResults {
  user?: object;
  entities?: object;
  orders?: object;
  line_items?: object;
  customer_profiles?: object;
  commerce_payment_transactions?: object;
}
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  events = new BehaviorSubject<any>(null);
  currentEvents = this.events.asObservable();
  event = new BehaviorSubject<any>(null);
  currentEvent = this.events.asObservable();

  constructor(private storage: Storage,
    private http: HttpClient,
    private router: Router,
    public alertCtrl: AlertController,
    private toastController: ToastController) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
    this.getEvents();
  }

  changeEvents(events: any) {
    this.events.next(events);
  }

  async getEvents() {
    this.changeEvents(await this.storage.get('events'));
  }

  // Create and expose methods that users of this service can
  // call, for example:
  set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  async get(key: string) {
    return await this._storage?.get(key);
  }

  getEach() {
    this._storage.forEach((value, key, index) => {
      if (key.startsWith('storedreq')) {
        this.get(key).then(val => {
          console.log(val);
        });
      }
    });
  }

  async checkMyID() {
    return await this._storage.get('stored_records').then(records => {
      if (records?.length) {
        return true;
      } else {
         return false;
      }
    });
  }

  async pushYourIDPage(feature: string) {
    const alert = await this.alertCtrl.create({
      header: 'Add your Conference Ticket',
      message: feature + ', you must first add your conference ticket.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Add Ticket',
          handler: () => {
            this.router.navigateByUrl('/tabs/tickets');
          }
        }
      ]
    });
    await alert.present();
  }

  async checkFavorited(event: ConferenceEvent) {
    return this.storage.get('favEvents').then(res => {
      if (res === null) {return false;}
      if (res.indexOf(event.nid) > -1) {
        return true;
      } else {
        return false;
      }
    });
  }

  async toggleFavoriteEvent(event: ConferenceEvent, value: boolean): Promise<any> {
    let favEvents: Array<number> = await this.storage.get('favEvents');
    if (favEvents) {
        const index = favEvents.map((id) => id).indexOf(event.nid);
        if (index === -1 && value === false) {
          favEvents.push(event.nid);
          value = true;
        }
        if (index > -1) {
          favEvents = favEvents.filter(e => e !== event.nid);
          value = false;
        }
    } else {
      favEvents = [event.nid];
      value = true;
    }
    this.storage.set('favEvents', favEvents);
    this.changeEvents(favEvents);
    return value;
  }

  // get(key: string): Observable<any> {
  //   return from(this._storage.get(key)).pipe(
  //     switchMap(storedOperations => {
  //       const storedObj = JSON.parse(storedOperations);
  //       if (storedObj && storedObj.length > 0) {
  //         console.log(storedObj);
  //         return storedObj;
  //       } else {
  //         console.log('no local events to sync');
  //         return of(false);
  //       }
  //     })
  //   );
  // }

  getAll(key: string): Observable<any> {
    return from(this._storage.get(key)).pipe(
      map(storedOperations => {
        const storedObj = JSON.parse(storedOperations);
        if (storedObj && storedObj.length > 0) {
          console.log(storedObj);
          return storedObj;
        } else {
          console.log('no local data for type: ' + key);
          return of(false).toPromise();
        }
      })
    );
  }

  async storeRecord(nid: number, lastName: string, order: number, feedbackData: FeedbackData) {
    const storageKey = 'stored_records';
    return this._storage.get(storageKey).then(storedObj => {
        if (storedObj) {
          const index = storedObj.map((o) => o.nid).indexOf(nid);
          if (index !== -1) {
            storedObj[index] = {nid, lastName, order, feedbackData};
          } else {
            storedObj.unshift({nid, lastName, order, feedbackData});
          }
        } else {
          storedObj = [{nid, lastName, order, feedbackData}];
        }
        return this._storage.set(storageKey, storedObj);
    });
  }

  async deleteRecord(nid: number) {
    const storageKey = 'stored_records';
    const entities = await this._storage.get(storageKey);
    const storedObj = entities.filter(record => record.nid !== nid);
    return this._storage.set(storageKey, storedObj);
  }

  async storeRequest(url, type, data, storageKey, uid) {
    const toast = this.toastController.create({
      message: `Your data is stored locally because you seem to be offline.`,
      duration: 1500,
      position: 'bottom'
    });
    toast.then(res => res.present());

    const action: StoredRequest = {
        url,
        type,
        data,
        time: new Date().getTime(),
        reqID: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
        id: uid,
        entityType: storageKey,
        send: true
      };

    return this._storage.get(STORAGE_REQ_KEY + storageKey).then(storedOperations => {
      let storedObj = JSON.parse(storedOperations);
        if (storedObj) {
          storedObj.push(action);
        } else {
          storedObj = [action];
        }
        // Save old & new local transactions back to Storage
        return this._storage.set(STORAGE_REQ_KEY + storageKey, JSON.stringify(storedObj));
    });
  }

  async updateRequest(op: StoredRequest, storageKey) {
    let storedObj = await this._storage.get(storageKey);
    storedObj = JSON.parse(storedObj);
    const index = storedObj.map((o) => o.reqID).indexOf(op.reqID);
    storedObj[index] = op;
    return this._storage.set(storageKey, JSON.stringify(storedObj));
  }

  async deleteRequest(op: StoredRequest, storageKey) {
    let entities = await this._storage.get(storageKey);
    entities = JSON.parse(entities);
    const storedObj = entities.filter(obj => obj.reqID !== op.reqID);
    return this._storage.set(storageKey, JSON.stringify(storedObj));
  }

  async storeNotification(storageKey: string, notification: PushNotificationSchema) {
    let newEntity = [];
    await this._storage.get(storageKey).then((storedObj: PushNotificationSchema[]) => {
      if (storedObj) {
        const index = storedObj.map((o: PushNotificationSchema) => o.id).indexOf(notification.id);
        if (index !== -1) {
          storedObj[index] = notification;
        } else {
          storedObj.unshift(notification);
        }
      } else {
          storedObj = [notification];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  async deleteNotification(storageKey: string, notification: PushNotificationSchema) {
    const notifications: PushNotificationSchema[] = await this._storage.get(storageKey);
    console.log('the stored notifications.', notifications);
    const storedObj = notifications.filter(obj => obj.id !== notification.id);
    return await this._storage.set(storageKey, storedObj);
  }

  async subscribeTopic(storageKey: string, topic: string) {
    let newEntity = [];
    await this._storage.get(storageKey).then(storedObj => {
      if (storedObj) {
        const index = storedObj.map((o) => o.id).indexOf(topic);
        if (index !== -1) {
          storedObj[index] = topic;
        } else {
          storedObj.push(topic);
        }
      } else {
          storedObj = [topic];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  async unsubscribeTopic(storageKey: string, topic: string) {
    const entities: Array<string> = await this._storage.get(storageKey);
    const storedObj = entities.filter(k => k !== topic);
    return this._storage.set(storageKey, storedObj);
  }

  async storeEntity(storageKey: string, entity: any, entityKey: 'nid' | 'id' | 'item_id') {
    let newEntity = [];
    await this._storage.get(storageKey).then(storedObj => {
      if (storedObj) {
        const index = storedObj.map((o) => o[entityKey]).indexOf(entity[entityKey]);
        if (index !== -1) {
          storedObj[index] = entity;
        } else {
          storedObj.push(entity);
        }
      } else {
          storedObj = [entity];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  async storeWebformSubmission(storageKey: string, entity: any) {
    let newEntity = [];
    await this._storage.get(storageKey).then(storedObj => {
      if (storedObj) {
        const index = storedObj.map((o) => o.id).indexOf(entity.id);
        if (index !== -1) {
          storedObj[index] = entity;
        } else {
          storedObj.push(entity);
        }
      } else {
          storedObj = [entity];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }


  async clear() {
    this._storage.forEach((v, k, i) => {
      if (!k.startsWith('login_')) {
        this._storage.remove(k);
      }
    });
  }

  async clearBiometrics() {
    this._storage.forEach((v, k, i) => {
      if (k.startsWith('login_BiometryType')) {
        this._storage.remove(k);
      }
    });
  }

  async remove(storageKey: string) {
    return this._storage.remove(storageKey);
  }

}



