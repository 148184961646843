import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertController, IonInput, IonSearchbar, LoadingController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { FoundRegistrations } from '../services/drupal7/models/forms';
import { FlagIonic, ViewOptions } from '../services/drupal7/models';
import { EntityServiceCustom } from '../services/entity.service';
import { MessagesService } from '../services/messages.service';
import { FlagServiceCustom } from '../services/flag.service';
import { DelegateRegistrationRecordLocator } from '../services/drupal7/models/node-types';
// import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { CodeScanService } from '../services/codescan.service';
import jsQR from 'jsqr';
import { StorageService } from '../services/storage.service';
@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BadgesComponent implements OnInit {
  @ViewChild('searchInput', {static: false}) searchInput: IonSearchbar;
  @ViewChild('scannedInputField', {static: false}) scannedInputField: IonInput;
  @ViewChild('video', {static: false}) video: ElementRef;
  @ViewChild('canvas', {static: false}) canvas: ElementRef;

  scanActive = false;
  localStream: any;

  videoElement: any;
  canvasElement: any;
  canvasContext: any;

  loading: HTMLIonLoadingElement;

  data: DelegateRegistrationRecordLocator[] = [];

  noResultsData = true;
  inputData: string | number;
  scannedInput: string | number;
  editObj = {};

  cardBadge = false;

  constructor(
    public router: Router,
    private flag: FlagServiceCustom,
    private alertCtrl: AlertController,
    private platform: Platform,
    private loadingCtrl: LoadingController,
    private barcodeScanner: BarcodeScanner,
    private codeScan: CodeScanService,
    private entity: EntityServiceCustom,
    public storage: StorageService,
    private message: MessagesService
    ) {
      const url = this.router.url;
      const page = url.split('/');
      const id = page[3];
      const order = page[4];
      if (id && order) {
        this.find(+id, +order);
      }
   }

   async ngOnInit() {
   }

   ngAfterViewInit() {
    this.videoElement = this.video.nativeElement;
    this.canvasElement = this.canvas.nativeElement;
    this.canvasContext = this.canvasElement.getContext('2d');
    this.storage.get('stored_records').then(records => {
      if (records) {
        for (const record of records) {
          this.find(record.nid, record.order, true, 'Loading records...');
        }
      }
    });
   }

   async startScan() {
    this.localStream = await navigator.mediaDevices.getUserMedia({
      video: {facingMode: 'environment'}
    });
    this.videoElement.srcObject = this.localStream;
    this.videoElement.setAttribute('playsinline', true);
    this.videoElement.play();
    this.loading = await this.loadingCtrl.create({});
    await this.loading.present();
    requestAnimationFrame(this.scan.bind(this));
   }

   async scan() {
    if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
      if (this.loading) {
        await this.loading.dismiss();
        this.loading = null;
        this.scanActive = true;
      }

      this.canvasElement.height = this.videoElement.videoHeight;
      this.canvasElement.width = this.videoElement.videoWidth;
      this.canvasContext.drawImage(
        this.videoElement,
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      const imageData = this.canvasContext.getImageData(
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert'
      });
      if (code) {
        this.scanActive = false;
        this.message.showLoading('Searching...', false, 1500);
        const res = await this.codeScan.parseScan(code.data);
        if (res.id && res.order_id) {
          this.find(res.id, res.order_id);
        } else {
          this.message.presentAlert('Invalid Scan', 'The scanned code is invalid.');
        }
        this.stopScan();
      } else {
        if (this.scanActive) {
          requestAnimationFrame(this.scan.bind(this));
        }
      }
    } else {
      requestAnimationFrame(this.scan.bind(this));
    }
   }
  
   async stopScan() {
    this.scanActive = false;
    this.localStream.getTracks().forEach(track => track.stop());
  }

  async find(nid: number, order: number, showLoading: boolean = true, loadingTitle: string = 'Searching...') {
    if (!nid || !order) {
      return;
    }
    const options: ViewOptions = {
      display_id: 'app_my_delegate',
      args: [nid, order],
    };
    if (showLoading) {
      this.message.showLoading(loadingTitle, false, 2000);
    }
    return this.entity.findRegistration('record-locator', options).then(async (res: FoundRegistrations) => {
      this.entity.setupBadgeType(res.results);
      const storedRecords = await this.storage.get('stored_records');
      for (const result of res.results) {
        if (storedRecords) {
          const index = this.data.map((o) => o.nid).indexOf(+nid);
          if (index !== -1) {
            this.data[index] = result;
          } else {
            this.data.unshift(result);
          }
        } else {
          this.data.unshift(result);
        }
        this.storage.storeRecord(nid, order, this.setupFeedbackObject(result));
      }
      this.noResultsData = res.noResultsData;
    }).catch(() => {
      return this.message.presentAlert('No results found', 'No results found. Please check that you have entered the correct information.')
    });

  }

  scanQRCode() {
    if (this.platform.is('capacitor')) {
      // document.querySelector('body').classList.add('scanner-active');
      // this.barcodeScanner();
      this.barcodeScanner.scan({showFlipCameraButton: true, preferFrontCamera: false}).then(async (barcodeData) => {
        if (barcodeData.text && !barcodeData.cancelled) {
          this.message.showLoading('Searching...', false, 1500);
          const res = await this.codeScan.parseScan(barcodeData.text);
          this.find(res.id, res.order_id);
        }
       }).catch(() => {
       });
    } else {
      this.startScan();
      // this.scannedInput = '';
      // this.scannedInputField.setFocus();
    }
  }

  // async barcodeScanner() {
  //   await BarcodeScanner.checkPermission({ force: true });
  //   BarcodeScanner.hideBackground();
  //   const barcodeData = await BarcodeScanner.startScan();
  //   if (barcodeData.hasContent) {
  //     document.querySelector('body').classList.remove('scanner-active');
  //     this.message.showLoading('Searching...', false, 1500);
  //     const res = await this.codeScan.parseScan(barcodeData.content);
  //     this.find(res.id, res.order_id);
  //   }
  // };

  async recordLocator(storageKey: string) {
    const alert = await this.alertCtrl.create({
      header: 'Locate your registration',
      inputs: [{
          name: 'nid',
          type: 'tel',
          id: 'nid',
          placeholder: 'Delegate ID'
      }, {
          name: 'order',
          type: 'tel',
          id: 'order',
          placeholder: 'Order ID'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'danger',
        },
        {
          text: 'Search',
          role: 'submit',
          cssClass: 'primary',
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (res: any) => {
      if (res.role !== 'cancel') {
        const options: ViewOptions = {
          display_id: 'app_my_delegate',
          args: [res.data.values.nid, res.data.values.order],
        };
        this.message.showLoading('Searching...', false, 2000);
        return this.entity.findRegistration('record-locator', options).then((registrations: FoundRegistrations) => {
          this.entity.setupBadgeType(registrations.results);
          for (const result of registrations.results) {
            this.data.unshift(result);
            this.storage.storeRecord(res.data.values.nid, res.data.values.order, this.setupFeedbackObject(result));
          }
          this.noResultsData = registrations.noResultsData;
        }).catch(() => {
          return this.message.presentAlert('No results found', 'No results found. Please check that you have entered the correct information.')
        });
      }
    }, () => {
      return this.message.presentAlert('No results found', 'No results found. Please check that you have entered the correct information.')
    });
  }

  async refresh(data: DelegateRegistrationRecordLocator, i: number) {
    const options: ViewOptions = {
      display_id: 'app_my_delegate',
      args: [data.nid, data.order_id],
    };
    this.message.showLoading('Refreshing data...', false, 2000);
    return this.entity.findRegistration('record-locator', options).then((res: FoundRegistrations) => {
      this.entity.setupBadgeType(res.results);
      const newVal = res.results.filter(val => val.nid === data.nid)[0];
      if (newVal) {
        this.data[i] = newVal;
      }
      console.log(this.data[i]);
      this.storage.storeRecord(data.nid, data.order_id, this.setupFeedbackObject(this.data[i]));
      this.noResultsData = res.noResultsData;
    }).catch(() => {
      return this.message.presentAlert('No results found', 'No results found. Please check that you have entered the correct information.')
    });
  }

  setupFeedbackObject(data: DelegateRegistrationRecordLocator) {
    const feedbackData = {
      first_name: data.contact_first_name,
      last_name: data.contact_last_name,
      email: data.contact_email,
      phone_number: data.contact_phone_number,
      type: data.delegate_type_value,
      nid: data.nid,
    }
    return feedbackData;
  }

  async flagEntity(item: any, flag: FlagIonic, flagValue: boolean) {
    flag.name = 'flag_checked_in'
    flag.label = 'Conference Check-in';

    const flagMessage = 'Flagged ' + flag.label + ' for ' + item.contact_first_name + ' ' + item.contact_last_name;
    const unflagMessage = 'Unflagged ' + flag.label + ' for ' + item.contact_first_name + ' ' + item.contact_last_name;
    const newFlagStatus = await this.flag.flagEntity(item, flag, flagValue, {user: {uid: 1844}}, flagMessage, unflagMessage, '', true, true);
    item['flag_'+flag.name] = newFlagStatus;
}

  remove(nid: number, i: number) {
    this.data.splice(i, 1);
    this.storage.deleteRecord(nid.toString());
  }

  doRefresh(event: any) {
    this.storage.get('stored_records').then(records => {
      if (records) {
        for (const record of records) {
          this.find(record.nid, record.order, true, 'Loading records...');
        }
      }
    });
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }



}
