import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { ConferenceEvent, SessionGroup, SessionGroupTimes } from 'src/app/models/conference-event.model';
import { FlagAction } from '../services/drupal7/models';
import { FlagService } from '../services/drupal7/drupal7-services.module';
import { MessagesService } from '../services/messages.service';
import { EntityServiceCustom } from '../services/entity.service';
import { IonicModule, IonModal, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonService } from '../services/common.service';
import { OverlayEventDetail } from '@ionic/core/components';
import { SessionDetailsComponent } from './event/event.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})

export class SessionScheduleComponent implements OnInit {

  private modalCtrl = inject(ModalController);
  private entity = inject(EntityServiceCustom);
  private commonService = inject(CommonService);
  private message = inject(MessagesService);
  private flagService = inject(FlagService);
  public storage = inject(StorageService);

  @ViewChild(IonModal) modal: IonModal;

  @Input() eventType: [];
  searchTerm: '';
  results: SessionGroup[] = [];
  filteredDays: SessionGroup[] = [];
  tracks = [];
  eventTypes = [];
  eventBuildings = [];
  times = [];
  resultsCount = 10;
  itemsPerPage = [5, 10, 20, 40, 60, 100];
  years = [2015, 2016, 2018, 2023];
  filterYear: string;
  showFilters: boolean = false;

  filterManualOptions = {
    event_type_value: 'SLC Asia Session',
    day: '',
    title: '',
    location: '',
    track: '',
    favorited: false,
  };

  filterManualOptionsDraft = {
    event_type_value: 'SLC Asia Session',
    day: '',
    location: '',
    track: '',
  };

  defaultOptions: any;

  constructor() {}

  ngOnInit() {
    this.entity.currentEvents.subscribe(events => {
      if (events) { 
        const clonedEvents = this.commonService.deepCopy(events);
        this.processResults(clonedEvents);
      }
    });
  }

  defaultFilters() {
    this.defaultOptions = this.commonService.deepCopy(this.filterManualOptionsDraft);
  }

  cancel() {
    this.filterManualOptionsDraft = this.defaultOptions;
    this.defaultOptions = null;
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    Object.keys(this.filterManualOptionsDraft).map(k => {
      this.filterManualOptions[k] = this.filterManualOptionsDraft[k];
    });
    // this.slcapi.filter.next(this.filterManualOptions);
    this.modal.dismiss(true, 'confirm');
  }  

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      this.filterManualDays();
    }
  }

  toggleFavorited() {
    this.filterManualOptions.favorited = !this.filterManualOptions.favorited;
    this.filterManualDays();
  }

  hasEvents(group: SessionGroupTimes) {
    const test = group.events.find(e => !e.hidden);
    if (test) {
      return true;
    }
    return false;
  }

  hasFavoriteEvents() {
    if (this.filterManualOptions.favorited) {
      if (this.results.length) {
        for (const day of this.results) {
          if (day.events_by_time.length) {
            for (const g of day.events_by_time) {
              if (g.events.length) {
                return true;
              }
            }
          }
        }
      }
      return false;
    } else {
      return true;
    }
  }

  async openModal(event: ConferenceEvent) {
    const modal = await this.modalCtrl.create({
      component: SessionDetailsComponent,
      componentProps: {
        event
      }
    });
    await modal.present();
    await modal.onDidDismiss().then(res => {

    });
  }

  segmentChanged(ev: any) {
    if (this.results) {
      if (ev.detail.value === 'favorited') {
        for (const group of this.results) {
          for (const g of group.events_by_time) {
            g.events.map(async (event: ConferenceEvent) => {
              event.favorited = await this.storage.checkFavorited(event);
              event.favorited ? event.hidden = false : event.hidden = true;
            });
          }
        }
      }
    }
    if (ev.detail.value === 'all') {
      for (const group of this.results) {
        for (const g of group.events_by_time) {
          g.events.map(e => e.hidden = false);
        }
      }
    }
  }

  processResults(data: SessionGroup[]) {

    data.map((group, i) => {
      data[i].events = group.events.filter(event => event.event_type === 'session');

      data[i].events_by_time.map((groupedEvents, j) => {
        data[i].events_by_time[j].events = groupedEvents.events.filter(e => e.event_type === 'session');
      });
    });
   data.map(group => {
      if (group.events.length === 0) {
        group.hidden = true;
      }
    });

    for (const group of data) {
      Object.values(group.events_by_time).map((groupedEvents: SessionGroupTimes) => {
        groupedEvents.events.map(async (event: ConferenceEvent) => {
          event.favorited = await this.storage.checkFavorited(event);
        })
      });
    }

    const events = data.flatMap((group) => {
      return group.events;
    });

    const filterTypes = this.commonService.getEventPropsValuesWithCount(events, ['event_type_value', 'location', 'track']);
    if (filterTypes) {
      this.eventBuildings = filterTypes.location;
      this.tracks = filterTypes.track;
      this.eventTypes = filterTypes.event_type_value;
    }

    this.filteredDays = this.commonService.deepCopy(data);
    this.results = data;

  }

  async toggle(conferenceEvent: ConferenceEvent) {
    const newVal = await this.storage.toggleFavoriteEvent(conferenceEvent, conferenceEvent.favorited);
    conferenceEvent.favorited = newVal;
    this.flagEntity(conferenceEvent, 'flag_favorite_event', newVal);
  }

  async flagEntity(item: ConferenceEvent, flagName: string, flagAction: boolean) {
    const action = flagAction === true ? 'flag' : 'unflag';
    const flagging: FlagAction = {
      flag_name: flagName.replace('flag_', ''),
      entity_id: item.nid,
      skip_permission_check: true,
      action,
      uid: 0
    };

    this.flagService.flag(flagging).then(data => {
    });
  }

  setFilteredResults() {
    this.filterResults(this.searchTerm);
  }

  filterResults(searchTerm) {
    for (const group of this.results) {
      for (const g of group.events_by_time) {
        for (const event of g.events) {
          event.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ? event.hidden = false : event.hidden = true;
        }
      }
    }
  }

  filterManualDays() {
    if (this.modal) {
      this.modal.dismiss();
    }

    for (let i = 0; i < this.results.length; i++) {
      for (let j = 0; j < this.results[i].events_by_time.length; j++) {
        this.results[i].events_by_time[j].events = this.filteredDays[i].events_by_time[j].events.filter(x => {
          for (const key of Object.keys(this.filterManualOptions)) {
            const val = this.filterManualOptions[key];
            
            if (key === 'title') {
              if (x.room.toLowerCase().indexOf(val.toLowerCase()) !== -1 || 
                  x.title.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
                continue;
              } else {
                return false; // Exclude session
              }
            } else if (val && x[key] !== val) {
              return false; // Exclude session
            }
          }
          
          return true; // Include session
  
        });

      } 
    }

  }

  filter(val: any, obj: SessionGroup[], key: string) {
    return obj.map(res => res.events.filter(x => {
      if (x[key] !== val && val !== 'all') {
        x.hidden = true;
      } else { x.hidden = false;}
    }));
  }

  refresh(showLoading: boolean = true) {
    if (showLoading) {
      this.message.showLoading('Refreshing...', false, 2000);
    }
    this.storage.remove('events');
    this.entity.getEvents();
  }

  doRefresh(event) {
    this.refresh();
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

}
