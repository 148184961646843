export const environment = {
  production: true,
  version: '2.1.0',
  firebase: {
    apiKey: 'AIzaSyCWSmpjVHri8quQD246r0LL_BXBnBY8HL0',
    authDomain: 'slc-asia.firebaseapp.com',
    databaseURL: 'https://slc-asia.firebaseio.com',
    projectId: 'slc-asia',
    storageBucket: '',
    messagingSenderId: '376219172359',
    appId: '1:376219172359:web:8e6b3a5a146d2a2d'
  },
  flag: {
    flagTypes: {
      badgeFlags: ['badge_printed'],
      confirmFlags: ['delegate_paid', 'not_attending'],
      disabledIfPaid: [],
      checkInOutFlags: ['checked_in'],
      selfCheckInOutFlags: ['checked_in'],
    }
  },
  checkInType: {
    entityType: 'node',
    bundle: 'delegate_registration'
  },
  badgeTemplate: 'https://slconferenceasia.com/sites/default/files/slc-asia-badge-template.svg',
  badgeType1: ['professional_businessman'],
  badgeType2: [],
  badgeRequiresReprint: ['contact_first_name', 'contact_last_name', 'linked_church', 'linked_church_value', 'country', 'country_value', 'delegate_type', 'delegate_type_value'],
  BaseURL: 'https://slconferenceasia.com/',
  ApiURL: 'https://slconferenceasia.com/api/',
  WebformURL: 'https://slconferenceasia.com/webform/',
  AuthorizedRoles: ['app', 'administrator', 'conference admin', 'volunteer', 'group manager']
};
