import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class FeedbackComponent implements OnInit {
  private modalController = inject(ModalController);

  @Input() delegate: FeedbackData;
  feedback = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    type: '',
    nid: 0,
    easy: '',
    heard: '',
    helpful: '',
    futureSessions: '',
    changes: '',
    comments: ''
  };

  constructor() {}

  ngOnInit() {
    if (this.delegate?.nid) {
      Object.keys(this.delegate).map(key => {this.feedback[key] = this.delegate[key]});
    }
  }

  cancelModal() {
    this.modalController.dismiss();
  }

  filter() {
    this.modalController.dismiss({
      feedback: this.feedback
    });
  }

}

export interface FeedbackData {
  first_name: string;
  last_name: string;
  email?: string;
  phone_number?: string;
  type?: string;
  nid: number;
  easy?: string;
  heard?: string;
  helpful?: string;
  futureSessions?: string;
  changes?: string;
  comments?: string;
}
