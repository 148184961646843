// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list-header {
  align-items: center;
  flex-flow: column;
}
ion-list-header h3 {
  margin-top: 20px;
  margin-bottom: 5px;
}
ion-list-header ion-button {
  margin-top: 5px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/home/home.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,iBAAA;AACF;AAAE;EACE,gBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,eAAA;EACA,mBAAA;AAEJ","sourcesContent":["ion-list-header {\n  align-items: center;\n  flex-flow: column;\n  h3 {\n    margin-top:20px;\n    margin-bottom: 5px;\n  }\n  ion-button {\n    margin-top: 5px;\n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
