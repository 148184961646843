import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { CommerceCustomerProfile } from '../models';

/**
 * node service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class CommerceCustomerProfileService extends MainService {
  entityType = 'commerce_customer_profile';

  /**
   * impelement index resource
   *
   * @return array of profiles
   */
  getAllProfiles(): Promise<CommerceCustomerProfile[]> {
    return this.get();
  }

  /**
   * implement retrieve resource
   *
   * @param profileId profile id to retrieve
   * @return object of the profile entity
   */
  getProfileById(profileId: number): Promise<CommerceCustomerProfile> {
    return this.get(profileId);
  }

  /**
   * implement create resource
   *
   * @param profile profile entity to create
   * @return created profile details
   */
  createProfile(profile: CommerceCustomerProfile): Promise<CommerceCustomerProfile> {
    return this.post(profile);
  }

  /**
   * implement update resource
   *
   * @param profile profile to update
   * @return updated profile details
   */
  updateProfile(profile: CommerceCustomerProfile): Promise<CommerceCustomerProfile> {
    console.log(profile);
    return this.put(profile, profile.profile_id);
  }

  /**
   * implement delete resource
   *
   * @param profileId profile id to delete
   * @return array true if profile deleted, otherwise return false array
   */
  deleteProfile(profileId: number): Promise<boolean[]> {
    return this.delete(profileId);
  }

}
