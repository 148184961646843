import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { Speaker, SpeakerGroup } from '../models/conference-event.model';
import { MessagesService } from '../services/messages.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonModal, ModalController } from '@ionic/angular';
import { EntityServiceCustom } from '../services/entity.service';
import { SpeakerDetailsComponent } from './speaker/speaker.component';
import { CommonService } from '../services/common.service';
import { OverlayEventDetail } from '@ionic/core/components';

@Component({
  selector: 'app-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class SpeakersComponent implements OnInit {

  private entityService = inject(EntityServiceCustom);
  private commonService = inject(CommonService);
  private router = inject(Router);
  private message = inject(MessagesService);
  public storage = inject(StorageService);
  private modalCtrl = inject(ModalController);

  @ViewChild(IonModal) modal: IonModal;

  results: SpeakerGroup[] = [];
  filteredSpeakers: SpeakerGroup[] = [];
  searchTerm: '';

  filterManualOptions = {
    group: '',
    title: '',
  };

  filterManualOptionsDraft = {
    group: '',
  };

  defaultOptions: any;
 
  constructor() {}

  ngOnInit() {
    this.storage.get('speakers').then((val) => {
      if (val === null || val === undefined) {
        this.getSpeakers();
      } else {
        this.results = this.groupByCategory(val);
      }
    });
  }

  defaultFilters() {
    this.defaultOptions = this.commonService.deepCopy(this.filterManualOptionsDraft);
  }

    cancel() {
      this.filterManualOptionsDraft = this.defaultOptions;
      this.defaultOptions = null;
      this.modal.dismiss(null, 'cancel');
    }
  
    confirm() {
      Object.keys(this.filterManualOptionsDraft).map(k => {
        this.filterManualOptions[k] = this.filterManualOptionsDraft[k];
      });
      // this.slcapi.filter.next(this.filterManualOptions);
      this.modal.dismiss(true, 'confirm');
    }  
  
    onWillDismiss(event: Event) {
      const ev = event as CustomEvent<OverlayEventDetail<string>>;
      if (ev.detail.role === 'confirm') {
        this.filterSpeakerGroup();
      }
    }

  async getSpeakers() {
    const speakers = await this.entityService.getViewWithOptions('speakers', {display_id: 'speakers'});
    speakers.results.map((speaker: Speaker) => {
      speaker.category = speaker.category.split(', ');
      speaker.field_speaker_category = speaker.field_speaker_category.split(', ');
    });
    this.storage.set('speakers', speakers.results);
    const groupedSpeakers = this.groupByCategory(speakers.results);
    this.results = groupedSpeakers;
  }

  groupByCategory(speakers: Speaker[]) {
    const queueGroups: object = {};
    let queues: any[] = [];
    speakers.map(speaker => {
      if (queues.indexOf(speaker.queue_title) === -1) {
        queues.push({qid: speaker.qid, queue_title: speaker.queue_title});
      }
    });
    const categories = ['Keynote Speakers', 'Ministry Workshop Speaker', 'Christian Business Workshop Speaker']
    for (const speaker of speakers) {
      for (let index = 0; index < speaker.field_speaker_category.length; index++) {
        const x = speaker.field_speaker_category[index];
        if (x && categories.indexOf(x) > -1) {
          queueGroups[x] = new SpeakerGroup(x, speakers.filter((o: Speaker) => o.field_speaker_category.indexOf(x) > -1), index+1, false);
        }
      }
    }
    Object.values(queueGroups).map((group: SpeakerGroup) => {
      const newSpeakers = [];
      for (const speaker of group.speakers) {
        const index = group.speakers.map(x => x.nid).indexOf(speaker.nid);
        newSpeakers[index] = speaker;
      }
      group.speakers = newSpeakers;
    });

    for (const x of queues) {
      if (x.queue_title) {
        queueGroups[x.queue_title] = new SpeakerGroup(x.queue_title, speakers.filter((o: Speaker) => o.queue_title === x.queue_title).sort((a: Speaker, b: Speaker) => a.position - b.position), x.qid, false);
      }
    }
    const queueResults = Object.values(queueGroups).sort((a, b) => {
      if (a.qid === 5) {a.qid = -99;} // Keynote Speakers
      if (b.qid === 6) {b.qid = -98;} // Roundtable Speakers
      return a.qid - b.qid;
    });

    this.filteredSpeakers = this.commonService.deepCopy(queueResults);

    return queueResults;
  }

  forceRefresh(showLoading: boolean = true) {
    if (showLoading) {
      this.message.showLoading('Refreshing...', false, 2000);
    }
    this.storage.remove('speakers');
    this.getSpeakers();
  }

  openSpeakerDetailsService(speaker) {
    this.router.navigate(['/speakers/' + speaker.nid], {state: {speaker}});
  }

  async openModal(speaker: Speaker) {
    const modal = await this.modalCtrl.create({
      component: SpeakerDetailsComponent,
      componentProps: {
        speaker
      }
    });
    await modal.present();
    await modal.onDidDismiss().then(res => {

    });
  }  

  segmentChanged(ev: any) {
    this.results.map(group => group.category === ev.detail.value ? group.hidden = false : group.hidden = true);
    if (ev.detail.value === 'allSpeakers') {
      this.results.map(group => group.hidden = false);
    }
  }

  filterManualSpeakers() {
    if (this.modal) {
      this.modal.dismiss();
    }

    console.log(this.filterManualOptions);

    for (let i = 0; i < this.results.length; i++) {
        this.results[i].speakers = this.filteredSpeakers[i].speakers.filter(x => {
          for (const key of Object.keys(this.filterManualOptions)) {
            const val = this.filterManualOptions[key];
            
            if (key === 'title') {
              if (x.title.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
                continue;
              } else {
                return false; // Exclude session
              }
            } else if (val && x[key] !== val) {
              return false; // Exclude session
            }
          }
          
          return true; // Include session
  
        });

    }

  }

  filterSpeakerGroup() {
    const group = this.filterManualOptions.group ? this.filterManualOptions.group : 'all';
    this.filter({detail: {value: group}});
  }

  filter(ev: any) {
    this.results.map(group => group.category === ev.detail.value ? group.hidden = false : group.hidden = true);
    if (ev.detail.value === 'all') {
      this.results.map(group => group.hidden = false);
    }
  }

  setFilteredResults() {
    this.results = this.filterResults(this.searchTerm);
  }

  filterResults(searchTerm) {
    for (const group of this.results) {
      for (const speaker of group.speakers) {
        speaker.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ? speaker.hidden = false : speaker.hidden = true;
      }
    }
    return this.results;
  }

  doRefresh(event: any) {
    this.storage.remove('speakers');
    this.getSpeakers();
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

}
