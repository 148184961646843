export interface ConferenceEvent {
    day: string;
    nid: number;
    room: string;
    speaker: string;
    time: string;
    title: string;
    track: string;
    type: string;
    hidden: boolean;
    favorited?: boolean;

    additional_notes?: string;
    day_of_week: string;
    event_type: string;
    event_type_value: string;
    field_event_livestreamed?: number;
    field_event_notes?: string;
    itinerary_contact?: string;
    linked_itinerary?: string
    number_traveling?: string;
    speaking_location?: string;
    status: boolean;

    // Taxonomy Term Relationship
    tid?: number;
    name?: string;
    description?: string;
    field_location_image?: string;

  }

  export class SessionGroup {
    day_of_week: string;
    day: string;
    events: Array<ConferenceEvent>;
    time?: string;
    hidden: boolean;
    constructor(dayOfWeek: string, day: string, events: Array<ConferenceEvent>, hidden: boolean, time?: string,) {
      this.day_of_week = dayOfWeek;
      this.day = day;
      this.time = time;
      this.events = events;
      this.hidden = hidden;
    }
  }

  export class SpeakerGroup {
    category: string;
    speakers: Array<Speaker>;
    qid?: number;
    hidden: boolean;
    constructor(category: string, speakers: Array<any>, qid: number, hidden: boolean) {
      this.category = category;
      this.speakers = speakers;
      this.qid = qid;
      this.hidden = hidden;
    }
  }
  export interface Speaker {
    title: string;
    last_name: string;
    image: string;
    location: string;
    speaker_title: string;
    category: any;
    hidden: boolean;
    nid: number;
    field_speaker_category: any;
    position: number;
    qid: number;
    queue_title: string;
  }

