/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { Storage } from '@ionic/storage-angular';
import { MessagesService } from './messages.service';
import { AlertController } from '@ionic/angular';
import { Pager } from './drupal7/models/forms';
import { EntityService, MainService, ViewService } from './drupal7/drupal7-services.module';
import { ViewOptions } from './drupal7/models';
import { throwError } from 'rxjs';
import { DrupalFormControlObject } from './drupal7/models/form-controls';
import { QRCode } from './drupal7/models/qrcode';
import { DelegateRegistrationRecordLocator } from './drupal7/models/node-types';
import { ConferenceEvent, SessionGroup } from '../models/conference-event.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EntityServiceCustom {

  constructor(
    private viewService: ViewService,
    private alertCtrl: AlertController,
    public message: MessagesService,
    private entityService: EntityService,
    private mainService: MainService,
    public storage: StorageService
    ) {}


    async getEventsLocal(url: string, options: ViewOptions) {
      return await this.storage.get('events').then(async (val) => {
        if (val) {
          this.getEvents(url, options);
          return val;
        } else {
          return this.getEvents(url, options);
        }
      });
    }
    
    async getEvents(url: string, options: ViewOptions) {
    return this.viewService.getView(url, options).then(async (res: any) => {
      if (!res.length) {
        return;
      }
      const result: object = {};
      res.map(async (event: ConferenceEvent) => {
        event.favorited = await this.storage.checkFavorited(event);
      });
      for await (const event of res) {
        result[event.day] = new SessionGroup(event.day_of_week, event.day, res.filter(o => o.day === event.day), false, null);
      }
      const arr = Object.values(result).sort((a, b) => a[1] - b[1]);
      // this.storage.set('events', arr);
      return arr;
    }).catch(() => {
      const returnedResult = {
        headers: null,
        pager: null,
        paidCount: null,
        results: null,
        noResultsData: true
      };
      return returnedResult;
    });
  }
  

  async findRegistration(url: string, options: ViewOptions, autoFlag: boolean = false) {
    return this.viewService.getView(url, options).then((res: any) => {
      if (!res.length) {
        return;
      }
      const result = [];
      const items = [];
      if (res.pager?.page === -1) {
        return;
      }
      if (res[0]) {
        Object.keys(res).map(key => key !== 'pager' ? items.push(res[key]) : null);
        for (const item of items) {
          result.push(item);
        }

        // Remove the duplicates from the array because they can't be removed from the api
        // because the data from the api is being sorted by order id, line item id, and then nid.
        // This is sorted this way on from the api because the badges are sorted this way. This makes
        // it so the data from the api and the physical badges are grouped and sorted the same way.

        // Need a different way of getting the page summary to display the correct number because we want to remove
        // duplicates from the final result.
        // const uniqueResult: Array<FormData> = Array.from(result.reduce((m, t) => m.set(t.nid, t), new Map()).values());
        const uniqueResult = result;
        let pager: Pager;
        if (res.pager) {
          pager = res.pager;
          pager.links = Array.from(new Array(res.pager?.pages), (x,i) => i);
        }
        this.mainService.removeProps(uniqueResult,['pager']);
        this.setupQRCode(uniqueResult);
        const paidCount = this.setupPaidCount(uniqueResult);
        const headers = Object.keys(uniqueResult[0]).map(key => key);
        const returnedResult = {
          headers,
          pager,
          paidCount,
          results: uniqueResult,
          noResultsData: false
        };
        return returnedResult;
      }
    }).catch(() => {
      const returnedResult = {
        headers: null,
        pager: null,
        paidCount: null,
        results: null,
        noResultsData: true
      };
      return returnedResult;
    });
  }

  setupQRCode(data: DelegateRegistrationRecordLocator[]) {
    for (const entity of data) {
      entity.qr_codes = [];
      const qrCodeDataValue = `%id=${entity.nid}^lineitemid=${entity.linked_line_item}^orderid=${entity.order_id}^uid=${entity.uid}^group=${entity.group_manager_uid}?`;
      const qrCodeData: QRCode = {
        id: entity.nid,
        line_item_id: entity.linked_line_item,
        order_id: entity.order_id,
        uid: entity.uid,
        group_manager: entity.group_manager_uid,
        qr_code_value: qrCodeDataValue
      };
      entity.qr_codes.push(qrCodeData);
    }
    return data;
  }

  setupQRCodeFromOrders(data: any) {
    for (const entity of data) {
      entity.qr_codes = [];
      if (entity.referenced_orders) {
        for (const order of entity.referenced_orders) {
          const qrCodeDataValue = `%id=${entity.nid}^lineitemid=${order.line_item_id}^orderid=${order.order_id}^uid=${order.uid}^group=${entity.group_manager}?`;
          const qrCodeData: QRCode = {
            id: entity.nid,
            line_item_id: order.line_item_id,
            order_id: order.order_id,
            uid: order.uid,
            group_manager: entity.group_manager,
            qr_code_value: qrCodeDataValue
          };
          entity.qr_codes.push(qrCodeData);
        }
      }
    }
    return data;
  }

  setupBadgeType(data: DelegateRegistrationRecordLocator[]) {
    for (const entity of data) {
      entity.class = 'regular';
      entity.badge_template = 'background-image: url(../../assets/imgs/slc-asia-card-badge-template-regular.svg) !important;';
      // if (environment.badgeType1.indexOf(entity.delegate_type) > -1) {
      //   entity.class = 'roundtable';
      //   entity.badge_template = 'background-image: url(../../assets/imgs/slc-asia-card-badge-template-roundtable.svg) !important;';
      // }
      if (entity.disable_checked_in && entity.flag_delegate_paid && !entity.flag_checked_in) {
        entity.contextual_message = entity.disabled_not_checked_in_message;
      } else if (entity.disable_checked_in && entity.flag_delegate_paid && entity.flag_checked_in) {
        entity.contextual_message = entity.disabled_checked_in_message;
      } else if (entity.disable_checked_in && !entity.flag_delegate_paid && !entity.flag_checked_in) {
        entity.contextual_message = entity.disabled_unpaid_message;
      } else if (entity.disable_checked_in && !entity.flag_delegate_paid && entity.flag_checked_in) {
        entity.contextual_message = entity.disabled_unpaid_message;
      }

      if (!entity.disable_checked_in && entity.flag_delegate_paid && !entity.flag_checked_in) {
        entity.contextual_message = entity.enabled_not_checked_in_message;
      } else if (!entity.disable_checked_in && entity.flag_delegate_paid && entity.flag_checked_in) {
        entity.contextual_message = entity.enabled_checked_in_message;
      } else if (!entity.disable_checked_in && !entity.flag_delegate_paid && !entity.flag_checked_in) {
        entity.contextual_message = entity.enabled_unpaid_message;
      } else if (!entity.disable_checked_in && !entity.flag_delegate_paid && entity.flag_checked_in) {
        entity.contextual_message = entity.enabled_unpaid_message;
      }
    }
    return data;
  }

  setupBadgeControls(data: any) {
    for (const entity of data) {
      entity.contact_first_name_size = '20';
      entity.contact_last_name_size = '20';
      entity.church_title_size = '10';
      entity.country_size = '10';
      // entity.seating_assignment_original = entity.seating_assignment;
      entity.church_title = entity.linked_church_value;
      entity.church_title_original = entity.linked_church_value;
    }
    return data;
  }

  setupUniqueOrders(data: any) {
    console.log(data);
    for (const entity of data) {
      entity.unique_orders = [];
      if (entity.referenced_orders) {
        for (const order of entity.referenced_orders) {
          const index = entity.unique_orders.map((o) => o.order_id).indexOf(order.order_id);
          if (index === -1) {
            entity.unique_orders.push(order);
          }
        }
      }
    }
    return data;
  }

  setupPaidCount(data: any) {
    let paidCount = 0;
      data.map(e => {
        paidCount += e.flag_delegate_paid ? 1 : 0;
      });
      return paidCount;
  }

  async presentUpdateFieldAlert(entityType: string, entity: any, formField: DrupalFormControlObject, field: any, inputs: Array<any>) {
    console.log(inputs);
    console.log(formField);
    const alert = await this.alertCtrl.create({
      header: formField.label,
      backdropDismiss: true,
      message: 'Enter the ' + field + ' for ' +  entity.contact_first_name + ' ' + entity.contact_last_name,
      inputs,
      buttons: [
        {
          text: 'Update',
          cssClass: 'primary',
          role: 'submit'
        }, {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (form: any) => {
      console.log(form);
      console.log(formField);
      if (form.role === 'submit') {
        const body = {};
        body[field] = form.data.values[field];
        if (form.data.values[field] === undefined) {
          body[field] = form.data.values;
        }
        console.log(body);
        this.message.showLoading('Updating ' + formField.label + ' from ' + entity[field] + ' to ' + body[field], false, 3000);
        return await this.entityService.updateEntity(entityType, body, entity.nid).then(res => res[field]);
      }
    }, err => throwError(err));
  }

}


