import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonicModule, LoadingController, ModalController } from '@ionic/angular';
import { FeedbackComponent } from '../modals/feedback/feedback.component';
import { SessionGroup } from '../models/conference-event.model';
import { AuthServiceService } from '../services/auth-service.service';
import { StorageService } from '../services/storage.service';
import { format, formatISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { MessagesService } from '../services/messages.service';
import { environment } from 'src/environments/environment.prod';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EntityServiceCustom } from '../services/entity.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class HomeComponent implements OnInit {

  private router = inject(Router);
  private message = inject(MessagesService);
  private entity = inject(EntityServiceCustom);
  private commonService = inject(CommonService);
  public loadingController = inject(LoadingController);
  public alertController = inject(AlertController);
  private storage = inject(StorageService);
  private auth = inject(AuthServiceService);
  private modalController = inject(ModalController);

  version = environment.version;

  results = [];
  discussions = [];
  originalData = [{events: []}];
  onlyFavEvents = [];
  favDiscussions = false;
  date = new Date();
  event = {
    nid: 0
  };

  constructor() {}

  ngOnInit() {
    this.entity.currentEvents.subscribe(events => {
      if (events) {
        const clonedEvents = this.commonService.deepCopy(events); 
        this.getCurrentSchedule(clonedEvents);
      }
    });
  }

  setDefaultDate() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = formatISO(toZonedTime(new Date(), 'Asia/Manila'));
    const currentDate = format(date, 'EEEE, d MMMM yyyy');
    return currentDate;
  }

  async submitFeedback() {
    const hasId = await this.storage.checkMyID();

    if (hasId) {
      const delegates = await this.storage.get('stored_records');
      let delegate = null;
      if (delegates[0]) {
        delegate = delegates[0].feedbackData;
      }
  
      const modal = await this.modalController.create({
        component: FeedbackComponent,
        cssClass: 'feedback',
        componentProps: {
          delegate
        }
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if (data.feedback) {
          this.auth.createFeedbackWebformSubmission(data.feedback);
        }
      }
     } else { this.storage.pushYourIDPage('To submit feedback'); }
  }

  refresh(showLoading: boolean = true) {
    if (showLoading) {
      this.message.showLoading('Refreshing...', false, 2000);
    }
    this.storage.remove('events');
    this.entity.getEvents();
  }

  doRefresh(event) {
    this.refresh();
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

  async getCurrentSchedule(data: SessionGroup[]) {
    const results = [];
    const currentDate = this.setDefaultDate();
    const todayEvents = data.filter(group => group.day === currentDate);
    if (todayEvents.length) {
      todayEvents[0].events = todayEvents[0].events.filter(sessionEvent => sessionEvent.event_type !== 'session');
      results.push(todayEvents[0]);
    } else {
      data[0].events = data[0].events.filter(sessionEvent => sessionEvent.event_type !== 'session');
      results.push(data[0]);
    }

    this.results = results;
  }

  async navigate(page: string) {
    this.router.navigate([page]);
  }

}
