// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card-header {
  flex-direction: column;
}

ion-card-title {
  margin-bottom: 10px;
}

.time {
  margin-inline-start: 15px;
}

ion-avatar {
  width: 72px;
  height: 72px;
}`, "",{"version":3,"sources":["webpack://./src/app/speakers/speaker/speaker.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AAAA;EACI,yBAAA;AAGJ;;AAAA;EACI,WAAA;EACA,YAAA;AAGJ","sourcesContent":["ion-card-header {\n    flex-direction: column;\n}\nion-card-title {\n    margin-bottom: 10px;\n}\n.time {\n    margin-inline-start: 15px;\n}\n\nion-avatar {\n    width: 72px;\n    height: 72px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
