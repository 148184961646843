import { Component, inject, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Platform, LoadingController, IonicModule, ModalController } from '@ionic/angular';
import { ConferenceEvent, SessionGroup, Speaker } from '../../models/conference-event.model';
import { FlagAction } from 'src/app/services/drupal7/models';
import { FlagService } from 'src/app/services/drupal7/drupal7-services.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SessionDetailsComponent } from 'src/app/events/event/event.component';

@Component({
  selector: 'app-speaker',
  templateUrl: './speaker.component.html',
  styleUrls: ['./speaker.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class SpeakerDetailsComponent implements OnInit {

  public platform = inject(Platform);
  private flagService = inject(FlagService);
  public loadingCtrl = inject(LoadingController);
  private modalCtrl = inject(ModalController);
  public storage = inject(StorageService);

  @Input() speaker: Speaker;
  events: any = [];
  hasEvents: boolean = false;
  authorized: boolean;
  loggedIn: boolean;
  favorited = false;

  constructor() { }

  ngOnInit() {

    this.storage.get('events').then((val: SessionGroup[]) => {
      if (val !== null) {
        const events = [];
        for (const group of val) {
          const speakingEvents = group.events.filter(event => event.speaker === this.speaker.title);
          if (speakingEvents.length) {
            this.hasEvents = true;
          }
          if (speakingEvents.length) {
            events.push({
              day: group.day,
              day_of_week: group.day_of_week,
              events: speakingEvents
            });
          }
        }
        this.events = events;
      }
    })
    .catch(error => { console.log('error:' , error); });
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  async openModal(event: ConferenceEvent) {
    const modal = await this.modalCtrl.create({
      component: SessionDetailsComponent,
      componentProps: {
        event
      }
    });
    await modal.present();
    await modal.onDidDismiss().then(res => {

    });
  }

async toggle(conferenceEvent: ConferenceEvent) {
  const newVal = await this.storage.toggleFavoriteEvent(conferenceEvent, conferenceEvent.favorited);
  conferenceEvent.favorited = newVal;
  this.flagEntity(conferenceEvent, 'flag_favorite_event', newVal);
}

async flagEntity(item: ConferenceEvent, flagName: string, flagAction: boolean) {
  const action = flagAction === true ? 'flag' : 'unflag';
  const flagging: FlagAction = {
    flag_name: flagName.replace('flag_', ''),
    entity_id: item.nid,
    skip_permission_check: true,
    action,
    uid: 0
  };

  this.flagService.flag(flagging).then(data => {
  });
}

}
