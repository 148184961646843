import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { Storage } from '@ionic/storage-angular';
import { MessagesService } from './messages.service';
import { AlertController } from '@ionic/angular';
import { Pager } from './drupal7/models/forms';
import { EntityService, MainService, ViewService } from './drupal7/drupal7-services.module';
import { FlagAction, ViewOptions } from './drupal7/models';
import { BehaviorSubject, throwError } from 'rxjs';
import { DrupalFormControlObject } from './drupal7/models/form-controls';
import { QRCode } from './drupal7/models/qrcode';
import { DelegateRegistrationRecordLocator } from './drupal7/models/node-types';
import { ConferenceEvent, SessionGroup, SessionGroupTimes } from '../models/conference-event.model';
import { StorageService } from './storage.service';
import { FlagServiceCustom } from './flag.service';

@Injectable({
  providedIn: 'root'
})
export class EntityServiceCustom {

  events = new BehaviorSubject<SessionGroup[]>(null);
  currentEvents = this.events.asObservable();

  constructor(
    private viewService: ViewService,
    private alertCtrl: AlertController,
    public message: MessagesService,
    private entityService: EntityService,
    public flag: FlagServiceCustom,
    public storage: StorageService
    ) {
      this.getEventsLocal();
    }

    changeEvents(events: any) {
      this.events.next(events);
    }

      // async toggle() {
      //   const newVal = await this.storage.toggleFavoriteEvent(this.event, this.event.favorited);
      //   this.event.favorited = newVal;
      //   this.flagEntity('flag_favorite_event', newVal);
      // }
    
      // async flagEntity(flagName: string, flagAction: boolean) {
      //   const action = flagAction === true ? 'flag' : 'unflag';
      //   const flagging: FlagAction = {
      //     flag_name: flagName.replace('flag_', ''),
      //     entity_id: this.event.nid,
      //     skip_permission_check: true,
      //     action,
      //     uid: 0
      //   };
    
      //   this.flag.flag(flagging).then(data => {
      //   });
      // }

    async getViewWithOptions(url: string, options: ViewOptions, setupOptions: EntitySetupOptions = {}) {
      options.obj_to_arr_props = environment.arrayFields;
      return this.viewService.getView(url, options).then((res) => {
        const results = res.results;
          if (!res) return false;
  
          if (setupOptions.autoFlag) {
            this.flag.autoflag(results);
          }
          if (setupOptions.qrCodes) {
            this.setupQRCode(results);
          }
          if (setupOptions.badgeType) {
            this.setupBadgeType(results);
          }
          if (setupOptions.uniqueOrders) {
            this.setupUniqueOrders(results);
          }
          if (setupOptions.badgeControls) {
            this.setupBadgeControls(results);
          }
  
          const returnedResult = {
            headers: res.headers,
            pager: res.pager,
            results,
          };
          if (setupOptions.paidCount) {
            returnedResult['paidCount'] = this.setupPaidCount(results);
          }
          
          return returnedResult;
      }).catch(err => err);
    }    

    async getEventsLocal() {
      const events = await this.storage.get('events');
      if (events) {
        this.changeEvents(events);
      }
      this.getEvents();
    }
    
  async getEvents() {
    const results = await this.viewService.getView('events').then(res => res.results);
    results.map(async (event: ConferenceEvent) => {
      event.favorited = await this.storage.checkFavorited(event);
    });
    const result: object = {};
    for (const event of results) {
      result[event.day] = new SessionGroup(event.day_of_week, event.day, results.filter(o => o.day === event.day), false, null);
    }

    // Group events by time
    Object.values(result).map((group: SessionGroup) => {
      const eventsByTime = Object.groupBy(group.events, e => e.time);
      Object.keys(eventsByTime).map((time) => {
        group.events_by_time.push(new SessionGroupTimes(time, eventsByTime[time]));
      });
    });
    
    const events = Object.values(result).sort((a, b) => a[1] - b[1]);
    this.storage.set('events', events);
    this.changeEvents(events);
  }

  setupQRCode(data: DelegateRegistrationRecordLocator[]) {
    for (const entity of data) {
      entity.qr_codes = [];
      const qrCodeDataValue = `%id=${entity.nid}^lineitemid=${entity.linked_line_item}^orderid=${entity.order_id}^uid=${entity.uid}^group=${entity.group_manager_uid}?`;
      const qrCodeData: QRCode = {
        id: entity.nid,
        line_item_id: +entity.linked_line_item,
        order_id: entity.order_id,
        uid: entity.uid,
        group_manager: entity.group_manager_uid,
        qr_code_value: qrCodeDataValue
      };
      entity.qr_codes.push(qrCodeData);
    }
    return data;
  }

  setupQRCodeFromOrders(data: any) {
    for (const entity of data) {
      entity.qr_codes = [];
      if (entity.referenced_orders) {
        for (const order of entity.referenced_orders) {
          const qrCodeDataValue = `%id=${entity.nid}^lineitemid=${order.line_item_id}^orderid=${order.order_id}^uid=${order.uid}^group=${entity.group_manager}?`;
          const qrCodeData: QRCode = {
            id: entity.nid,
            line_item_id: order.line_item_id,
            order_id: order.order_id,
            uid: order.uid,
            group_manager: entity.group_manager,
            qr_code_value: qrCodeDataValue
          };
          entity.qr_codes.push(qrCodeData);
        }
      }
    }
    return data;
  }

  setupBadgeType(data: DelegateRegistrationRecordLocator[]) {
    for (const entity of data) {
      entity.class = 'regular';
      entity.badge_template = 'background-image: url(../../assets/imgs/slc-asia-card-badge-template-regular.svg) !important;';
      // if (environment.badgeType1.indexOf(entity.delegate_type) > -1) {
      //   entity.class = 'roundtable';
      //   entity.badge_template = 'background-image: url(../../assets/imgs/slc-asia-card-badge-template-roundtable.svg) !important;';
      // }
      if (entity.disable_checked_in && entity.flag_delegate_paid && !entity.flag_checked_in) {
        entity.contextual_message = entity.disabled_not_checked_in_message;
      } else if (entity.disable_checked_in && entity.flag_delegate_paid && entity.flag_checked_in) {
        entity.contextual_message = entity.disabled_checked_in_message;
      } else if (entity.disable_checked_in && !entity.flag_delegate_paid && !entity.flag_checked_in) {
        entity.contextual_message = entity.disabled_unpaid_message;
      } else if (entity.disable_checked_in && !entity.flag_delegate_paid && entity.flag_checked_in) {
        entity.contextual_message = entity.disabled_unpaid_message;
      }

      if (!entity.disable_checked_in && entity.flag_delegate_paid && !entity.flag_checked_in) {
        entity.contextual_message = entity.enabled_not_checked_in_message;
      } else if (!entity.disable_checked_in && entity.flag_delegate_paid && entity.flag_checked_in) {
        entity.contextual_message = entity.enabled_checked_in_message;
      } else if (!entity.disable_checked_in && !entity.flag_delegate_paid && !entity.flag_checked_in) {
        entity.contextual_message = entity.enabled_unpaid_message;
      } else if (!entity.disable_checked_in && !entity.flag_delegate_paid && entity.flag_checked_in) {
        entity.contextual_message = entity.enabled_unpaid_message;
      }
    }
    return data;
  }

  setupBadgeControls(data: any) {
    for (const entity of data) {
      entity.contact_first_name_size = '20';
      entity.contact_last_name_size = '20';
      entity.church_title_size = '10';
      entity.country_size = '10';
      // entity.seating_assignment_original = entity.seating_assignment;
      entity.church_title = entity.linked_church_value;
      entity.church_title_original = entity.linked_church_value;
    }
    return data;
  }

  setupUniqueOrders(data: any) {
    for (const entity of data) {
      entity.unique_orders = [];
      if (entity.referenced_orders) {
        for (const order of entity.referenced_orders) {
          const index = entity.unique_orders.map((o) => o.order_id).indexOf(order.order_id);
          if (index === -1) {
            entity.unique_orders.push(order);
          }
        }
      }
    }
    return data;
  }

  setupPaidCount(data: any) {
    let paidCount = 0;
      data.map(e => {
        paidCount += e.flag_delegate_paid ? 1 : 0;
      });
      return paidCount;
  }

  async presentUpdateFieldAlert(entityType: string, entity: any, formField: DrupalFormControlObject, field: any, inputs: Array<any>) {
    const alert = await this.alertCtrl.create({
      header: formField.label,
      backdropDismiss: true,
      message: 'Enter the ' + field + ' for ' +  entity.contact_first_name + ' ' + entity.contact_last_name,
      inputs,
      buttons: [
        {
          text: 'Update',
          cssClass: 'primary',
          role: 'submit'
        }, {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (form: any) => {
      if (form.role === 'submit') {
        const body = {};
        body[field] = form.data.values[field];
        if (form.data.values[field] === undefined) {
          body[field] = form.data.values;
        }
        this.message.showLoading('Updating ' + formField.label + ' from ' + entity[field] + ' to ' + body[field], false, 3000);
        return await this.entityService.updateEntity(entityType, body, entity.nid).then(res => res[field]);
      }
    }, err => throwError(err));
  }

}

export interface EntitySetupOptions {
  qrCodes?: boolean;
  childcare?: boolean;
  uniqueOrders?: boolean;
  badgeType?: boolean;
  badgeControls?: boolean;
  paidCount?: boolean;
  autoFlag?: boolean;
}


