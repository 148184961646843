import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  { path: 'schedule', loadChildren: () => import('./home/home.module').then(m => m.SchedulePageModule) },
  { path: 'events/:id',
    loadChildren: () => import('./events/event/event.module').then(m => m.SessionDetailsPageModule) },
  { path: 'feedback', loadChildren: () => import('./modals/feedback/feedback.module').then(m => m.FeedbackPageModule) },
  { path: 'speakers', loadChildren: () => import('./speakers/speakers.module').then(m => m.SpeakersPageModule) },
  {
    path: 'itineraries',
    loadChildren: () => import('./itineraries/itineraries.module').then( m => m.ItinerariesPageModule)
  },
  {
    path: 'tickets',
    loadChildren: () => import('./badges/badges.module').then(m => m.BadgesComponentModule)
  },
  {
    path: 'home/notification-center',
    loadChildren: () => import('./notification-center/notification-center.module').then( m => m.NotificationCenterPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
