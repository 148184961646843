import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { StorageService } from './app/services/storage.service';
import { HttpClientModule } from '@angular/common/http';
import { Drupal7ServicesModule, DrupalConstants, Settings } from './app/services/drupal7/public_api';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import '@angular/compiler';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';


if (environment.production) {
  enableProdMode();
}

const drupalSettings: Settings = {
  apiHost: 'slconferenceasia.com',
  apiEndPoint: 'api',
  language: 'und',
  requestTimeout: 15000,
  apiProtocol: 'https',
  allowOffline: true,
  cookieHeader: true,
  sessionDays: 23
};
DrupalConstants.Settings = drupalSettings;

bootstrapApplication(AppComponent, {
  providers: [
    Storage,
    StorageService,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxQRCodeModule,
    BarcodeScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(IonicModule.forRoot({})),
    importProvidersFrom(Drupal7ServicesModule),
    importProvidersFrom(IonicStorageModule.forRoot({
      name: '__slc_asia',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    })),
    provideRouter(routes),
  ],
});
