import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { LoadingController, ToastController, AlertController, NavController } from '@ionic/angular';
import { StorageService } from './storage.service';
import { FeedbackData } from '../modals/feedback/feedback.component';

@Injectable({
  providedIn: 'root'
})

export class AuthServiceService {

  token: string;
  sessionID: string;
  credentials = false;
  loginCredentials = { username: '', password: '' };

  headers = { 'Content-Type': 'application/json' };
  requestOptions = { headers: new HttpHeaders(this.headers) };
  headersBasic = {
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('restws_slcapp' + ':' + 'slc!7media')
  };
  requestOptionsBasic = { headers: new HttpHeaders(this.headersBasic) };
  headersAuth = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': ''
  };
  requestOptionsAuth = { headers: new HttpHeaders(this.headersAuth) };
  headersXCookie = {
    'Content-Type': 'application/json',
    'X-Cookie': ''
  };
  requestOptionsXCookie = { headers: new HttpHeaders(this.headersXCookie) };

  loginURL = environment.ApiURL + 'user/login'; // Login a user for a new session
  logoutURL = environment.ApiURL + 'user/logout'; // Logout a user session
  registerURL = environment.ApiURL + 'user/register'; // Register a user
  tokenURL = environment.ApiURL + 'user/token'; // Returns the CSRF token
  passwordResetURL = environment.ApiURL + 'user/request_new_password';
  delegateURL = environment.ApiURL + 'delegate'; // Get delegate information from a view
  ordersURL = environment.ApiURL + 'orders/'; // Get orders from a view
  flagDelegateURL = environment.ApiURL + 'flag/flag';
  churchURL = environment.ApiURL + 'churches';
  printCueURL = environment.ApiURL + 'badge-print-cue';
  webformURL = environment.WebformURL;
  webformSubmissionURL = environment.WebformURL + 'submission.json';
  nodeURL = environment.ApiURL + 'entity_node/';
  userURL = environment.ApiURL + 'entity_user/';
  paymentURL = environment.ApiURL + 'entity_commerce_payment_transaction/';
  elavonURL = 'https://api.convergepay.com/VirtualMerchant/processxml.do';

  constructor(
    private http: HttpClient,
    public storage: StorageService,
    public toastController: ToastController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public nav: NavController) { }

  async showLoading(title: string, dismiss: boolean, time: number) {
    const loading = await this.loadingCtrl.create({
      message: title,
      backdropDismiss: dismiss,
      duration: time
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  async presentToast(title: string) {
    const toast = await this.toastController.create({
      message: title,
      color: 'primary',
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  setupRequestOptions(session, action: boolean) {
    const cookie = session.session_name + '=' + session.sessid;
    if (action === true) {
      const headerDict = {
        'Content-Type': 'application/json',
        'X-Cookie': cookie
      };
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      return requestOptions;
    } else {
      const headerDict = {
        'Content-Type': 'application/json',
        'X-Cookie': cookie,
        'X-CSRF-Token': session.token
      };
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      return requestOptions;
    }
  }

  createFeedbackWebformSubmission(feedback: FeedbackData) {
    this.showLoading('Submitting your feedback...', true, 5000);
    const body = {
      webform: '6c707c3a-ca65-4d2c-8c8b-494196382689',
      submission: {
        uid: 0,
        data: {
          1: { values: [feedback.first_name] },
          2: { values: [feedback.last_name] },
          3: { values: [feedback.email] },
          5: { values: [feedback.phone_number] },
          6: { values: [feedback.type] },
          7: { values: [feedback.nid] },
          8: { values: [feedback.easy] },
          9: { values: [feedback.heard] },
          10: { values: [feedback.helpful] },
          11: { values: [feedback.futureSessions] },
          12: { values: [feedback.changes] },
          13: { values: [feedback.comments] },
        }
      }
    };

    return this.http.post(this.webformSubmissionURL, body, this.requestOptionsBasic).subscribe(res => {
      this.loadingCtrl.dismiss();
      this.presentToast('Thanks for your feedback!');
      return res;
    }, err => {
      console.log('error: ', err);
      this.presentToast('There was a problem. Please try again.');
      this.loadingCtrl.dismiss();
    });
  }

  getWebformResults(webformUUID: string) {
    this.showLoading('Fetching responses...', false, 5000);
    return this.http.get(this.webformURL + 'webform/' + webformUUID + '/submissions', this.requestOptionsBasic).pipe(map(res => {
      const result = [];
      result.push(res);
      this.loadingCtrl.dismiss();
      return result[0];
    }, error => {
      console.log(error);
      this.loadingCtrl.dismiss();
    }));
  }

  async checkSavedDelegate() {
    let delegate = {};
      await this.storage.get('delegate').then(async (delegateDATA) => {
        if (delegateDATA !== null || delegateDATA !== undefined) {
          delegate = await delegateDATA;
        }
      });
    return delegate;
  }

  async checkLoggedIn() {
    let loggedIn = false;
    const session = this.storage.get('session');
    if (session !== undefined) {
      loggedIn = true;
      return session;
    }
    return loggedIn;
  }
  
  async manualLogin() {
    const alert = await this.alertCtrl.create({
      header: 'Manual Login',
      inputs: [
        {
          name: 'email',
          type: 'email',
          id: 'email',
          placeholder: 'Email'
        },
        {
          name: 'password',
          type: 'password',
          id: 'password',
          placeholder: 'Password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: form => {
            this.presentLoading('Setting up your info...');
            console.log('Confirm Ok');
            this.loginCredentials.username = form.email;
            this.loginCredentials.password = form.password;

            this.login(this.loginCredentials).subscribe(async data => {
              this.nav.navigateForward('/dashboard');
              this.loadingCtrl.dismiss();
            }, (err) => {
              this.presentAlert('Check Your Info',
                'The info you provided does not match our records. Please check your information and try again.');
              this.loadingCtrl.dismiss();
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async presentLoading(title: string) {
    const loading = await this.loadingCtrl.create({
      message: title,
      duration: 7000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();

    console.log('Loading dismissed!');
  }

  async presentAlert(title: string, body: string) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: body,
      buttons: ['OK']
    });

    await alert.present();
  }

  login(credentials) {
    const body = {
      username: credentials.username,
      password: credentials.password
    };
    return this.http.post(this.loginURL, JSON.stringify(body), this.requestOptions).pipe(map(res => {
      const result = [];
      result.push(res);
      const result2 = result[0];
      this.storage.set('session', result2);
      this.storage.set('xCookie', result2.session_name + '=' + result2.sessid);
      const roles = Object.values(result2.user.roles);
      for (const role of roles) {
        for (const authorizedRole of environment.AuthorizedRoles) {
          if (role === authorizedRole) {
            this.credentials = true;
          }
        }
      }
      const currentRoles = Object.values(result2.user.roles);
      const newRoles = [];
      for (const role of currentRoles) {
        newRoles.push(role);
      }
      result2.user.user_roles = newRoles;
      return result2;
    }));
  }

  async logout() {
    this.storage.remove('session');
    this.storage.remove('xCookie');
    this.credentials = false;
  }
}
