/* eslint-disable max-len */
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FCM } from '@capacitor-community/fcm';
import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
  } from '@capacitor/push-notifications';
import { format } from 'date-fns';
import { MessagesService } from './messages.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
  })
export class FirebaseCloudMessagesService {

    constructor(private storage: StorageService,
      private router: Router,
      private ngZone: NgZone,
      private messages: MessagesService) {}

    initPush() {
        console.log('Initializing HomePage');

        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
          }
        });

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
          (token: Token) => {
            console.log('Push registration success, token: ' + token.value);
          }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
          (error: any) => {
            console.error('Error on registration: ' + error);
          }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            const dateSent = notification.data['google.c.a.ts'] + '000';
            notification.data.time_received = format(+dateSent, 'EEEE, MMMM dd, yyyy hh:mm aa');
            this.storage.storeNotification('notifications', notification);
            this.messages.presentToast(notification.title, 1500, 0, 'top', 'primary');
          }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
          (action: ActionPerformed) => {
            const dateSent = action.notification.data['google.c.a.ts'] + '000';
            action.notification.data.time_received = format(+dateSent, 'EEEE, MMMM dd, yyyy hh:mm aa');
            this.storage.storeNotification('notifications', action.notification);
            if (action.notification.data.eventPage) {
              this.ngZone.run(()=> {
                this.router.navigateByUrl('events/' + action.notification.data.eventPage, {replaceUrl: true, skipLocationChange: true});
                });
            } else {
              this.ngZone.run(()=> {
                this.router.navigateByUrl('home/notification-center');
              });
            }
          }
        );
    }

    subscribe(topic: string) {
      this.storage.subscribeTopic('topics', topic);
      FCM.subscribeTo({ topic })
      .then((r) => {
        this.messages.presentToast(`Subscribed to ${topic}`, 1500, 0, 'top', 'primary');
      })
      .catch((err) => console.log(err));
    }

    unsubscribe(topic: string) {
      this.storage.unsubscribeTopic('topics', topic);
      FCM.unsubscribeFrom({ topic })
      .then(() => {
        this.messages.presentToast(`Unsubscribed from ${topic}`, 1500, 0, 'top');
      })
      .catch((err) => console.log(err));
    }

    async getToken() {
      // Get FCM token instead the APN one returned by Capacitor
      return FCM.getToken()
      .then((r) => r.token)
      .catch((err) => err);
    }

   async getTopics() {
      // const options = { headers: new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   Authorization: 'Bearer ' + environment.firebase.serverKey}) };
      // const token = await this.getToken();
      // console.log(token);
      // return this.http.get(`https://iid.googleapis.com/iid/info/${token}?details=true`, options).toPromise();
      return await this.storage.get('topics');
    }

    deleteInstance() {
      FCM.deleteInstance()
      .then(() => alert(`Token deleted`))
      .catch((err) => console.log(err));
    }

    setAutoInit(enabled: boolean) {
      // Enable the auto initialization of the library
      FCM.setAutoInit({ enabled }).then(() => alert(`Auto init enabled`));

      // Check the auto initialization status
      FCM.isAutoInitEnabled().then((r) => {
      console.log('Auto init is ' + (r.enabled ? 'enabled' : 'disabled'));
      });
    }
}
