import { Component, inject, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ConferenceEvent, SessionGroup } from 'src/app/models/conference-event.model';
import { EntityServiceCustom } from '../services/entity.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { MessagesService } from '../services/messages.service';
import { CommonService } from '../services/common.service';
import { SessionDetailsComponent } from '../events/event/event.component';

@Component({
  selector: 'app-conference-schedule',
  templateUrl: './conference-schedule.component.html',
  styleUrls: ['./conference-schedule.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class ConferenceScheduleComponent implements OnInit {

  private entity = inject(EntityServiceCustom);
  private commonService = inject(CommonService);
  private modalCtrl = inject(ModalController);
  public storage = inject(Storage);
  private message = inject(MessagesService);

  searchTerm: '';
  results: SessionGroup[] = [];

  constructor() {}

  ngOnInit() {
    this.entity.currentEvents.subscribe(events => {
      if (events) {  
        const clonedEvents = this.commonService.deepCopy(events); 
        this.results = clonedEvents;
        this.processResults();
      }
    });
  }

  async processResults() {
    this.results.map((group, i) => {
      this.results[i].events = group.events.filter(event => event.event_type !== 'session');
    });
    this.results.map(group => {
      if (group.events.length === 0) {
        group.hidden = true;
      }
    });
  }

    async openModal(event: ConferenceEvent) {
      const modal = await this.modalCtrl.create({
        component: SessionDetailsComponent,
        componentProps: {
          event
        }
      });
      await modal.present();
      await modal.onDidDismiss().then(res => {
  
      });
    }

  segmentChanged(ev: any) {
    for (const group of this.results) {
      if (ev.detail.value !== group.day) {
        group.hidden = true;
      } else {
        group.hidden = false;
      }
    }
    if (ev.detail.value === 'all') {
      for (const group of this.results) {
        group.hidden = false;
      }
    }
  }

  setFilteredResults() {
    this.results = this.filterResults(this.searchTerm);
  }

  filterResults(searchTerm) {
    for (const group of this.results) {
      for (const event of group.events) {
        event.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ? event.hidden = false : event.hidden = true;
      }
    }
    return this.results;
  }

  refresh(showLoading: boolean = true) {
    if (showLoading) {
      this.message.showLoading('Refreshing...', false, 2000);
    }
    this.storage.remove('events');
    this.entity.getEvents();
  }

  doRefresh(event) {
    this.refresh();
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

}
