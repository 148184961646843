/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { APICommerceOrder, CommerceCustomerProfile, CustomerProfileAttachToEntity, ElavonProcess, ViewOptions } from '../models';
import { CommerceCheckoutResponse } from '../models/commerce';

/**
 * node service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class CommerceCheckoutService extends MainService {
  entityType = 'commerce_checkout';

  /**
   * impelement create resource
   *
   * @param order the order
   * @param billing the billing information
   * @param elavonTransaction the payment transaction
   * @param viewInfo the view info for the view we want to return
   * @return the order with the attached or updated billing and submitted payment
   */
  createCheckoutFlow(order: APICommerceOrder, billing: CommerceCustomerProfile | CustomerProfileAttachToEntity,
    elavonTransaction: ElavonProcess, viewInfo: ViewOptions): Promise<CommerceCheckoutResponse> {
    const body = {
        order,
        billing,
        payment: elavonTransaction,
        view_info: viewInfo
    };
    return this.post(body);
  }


}
