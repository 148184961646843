/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { FieldCollectionItemEntity, CreatedFieldCollectionItem } from '../models';

/**
 * node service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class FieldCollectionItemService extends MainService {
  entityType = 'entity_field_collection_item';

  /**
   * impelement index resource
   *
   * @return array of field collection items
   */
  getAllFieldCollectionItems(): Promise<FieldCollectionItemEntity[]> {
    return this.get();
  }

  /**
   * implement retrieve resource
   *
   * @param itemId item id to retrieve
   * @return object of the field collection item entity
   */
  getFieldCollectionItemById(itemId: number): Promise<FieldCollectionItemEntity> {
    return this.get(itemId);
  }

  /**
   * implement create resource
   *
   * @param item field collection item entity to create
   * @return created field collection item details
   */
  createFieldCollectionItem(item: FieldCollectionItemEntity): Promise<CreatedFieldCollectionItem> {
    return this.post(item);
  }

  /**
   * implement update resource
   *
   * @param item field collection item to update
   * @return updated field collection item details
   */
  updateFieldCollectionItem(item: {item_id: number}): Promise<FieldCollectionItemEntity> {
    return this.put(item, item.item_id);
  }

  /**
   * implement delete resource
   *
   * @param itemId item id to delete
   * @return array true if field collection item deleted, otherwise return false array
   */
  deleteFieldCollectionItem(itemId: number): Promise<boolean[]> {
    return this.delete(itemId);
  }

}
