import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import { StorageService } from './services/storage.service';

import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { FeedbackPageModule } from './modals/feedback/feedback.module';
import { BadgesComponent } from './badges/badges.component';
import { Drupal7ServicesModule } from './services/drupal7/drupal7-services.module';
import { DrupalConstants } from './services/drupal7/application/drupal-constants';

@NgModule({
  declarations: [AppComponent, BadgesComponent],
  imports: [
    BrowserModule,
    Drupal7ServicesModule,
    IonicModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    FeedbackPageModule,
    NgxQRCodeModule,
    ReactiveFormsModule,
    FormsModule,
    IonicStorageModule.forRoot({
      name: '__slc_asia',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    })],
  providers: [
    StorageService,
    StatusBar,
    SplashScreen,
    LocalNotifications,
    BarcodeScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private storageService: StorageService) {
    DrupalConstants.Settings = {
      apiHost: 'slconferenceasia.com',
      apiEndPoint: 'api',
      language: 'und',
      requestTimeout: 10000,
      apiProtocol: 'https',
      allowOffline: true,
      cookieHeader: true,
      sessionDays: 23
    };
    this.storageService.get('session').then(connection => {
      DrupalConstants.Connection = connection;
    });
  }
}
