import { Routes } from '@angular/router';
import { TabsComponent } from './tabs/tabs.component';
import { HomeComponent } from './home/home.component';
import { ConferenceScheduleComponent } from './conference-schedule/conference-schedule.component';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { SessionScheduleComponent } from './events/events.component';
import { SessionDetailsComponent } from './events/event/event.component';
import { SpeakersComponent } from './speakers/speakers.component';
import { SpeakerDetailsComponent } from './speakers/speaker/speaker.component';
import { TicketsComponent } from './tickets/tickets.component';
import { FeedbackComponent } from './modals/feedback/feedback.component';


export const routes: Routes = [
  { path: '', component: TabsComponent,
    children: [
      { path: 'home', children: [
        { path: '', component: HomeComponent},
        { path: 'schedule', children: [
          { path: '', component: ConferenceScheduleComponent},
          { path: ':id', component: SessionDetailsComponent}
      ]},
        { path: 'notification-center', component: NotificationCenterComponent},
      ]},
      { path: 'events', children: [
        { path: '', component: SessionScheduleComponent},
        { path: ':id', component: SessionDetailsComponent},
      ]},
      { path: 'speakers', children: [
        { path: '', component: SpeakersComponent},
        { path: ':id', children: [
          { path: '', component: SpeakerDetailsComponent},
          { path: ':id', component: SessionDetailsComponent}
        ]},
      ]},
      { path: 'tickets', component: TicketsComponent},
    { path: 'feedback', component: FeedbackComponent},

    { path: '', redirectTo: 'home', pathMatch: 'full'}
  ]},  
];

