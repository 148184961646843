import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { CommerceOrder } from '../models';

/**
 * node service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class CommerceCartService extends MainService {
  entityType = 'commerce_cart';

  /**
   * impelement index resource
   *
   * @return array of carts
   */
  async getAllOrders(): Promise<CommerceOrder[]> {
    return this.get().then(res => this.setupTransactions(res));
  }

  /**
   * See commerce_services_cart_create() line 54 in
   * in sites/all/modules/commerce_services/resources/cart.inc in Drupal 7
   * The module is designed for the current API user to create a new order for themselves.
   * However, the appUser needs to create an order for another user who won't be logged in.
   * We override this by checking the role of the API user to allow them to
   * create a new order for another user.
   *
   * The resource has an access check that should make it so we don't need to check if the user has
   * access to create the new order for another user
   * Example code to replace: $uid = array_key_exists(16, $user->roles) ? $data['uid'] : $user->uid;
   *
   * We will just set the uid if it exists in the body of the request since the resource has an
   * access callback of commerce_services_cart_create_access which checks user permission commerce_order_access
   * Example code: $uid = $data['uid'];
   *
   * The body in the request is an optional parameter.
   * The format for the "body" parameter is {uid: number}.
   *
   * @param cart cart entity to create
   * @return created cart details
   */
  createCommerceCart(cart: CommerceOrder): Promise<CommerceOrder> {
    return this.post(cart);
  }

  private setupTransactions(obj: any) {
    const cart = Object.keys(obj).map(orderID => obj[orderID]);
    if (cart.length) {
      for (const transaction of cart[0].commerce_payment_transactions) {
        transaction.created = new Date(Number(transaction.created) * 1000).toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        });
        transaction.amount = formatCurrency(parseFloat(transaction.amount) / 100, 'en-US', '$');
      }
    }
    return cart[0];
  }



}
