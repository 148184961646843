import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { CommercePaymentTransaction, Payment } from '../models';

/**
 * node service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class CommercePaymentTransactionService extends MainService {
  entityType = 'commerce_payment_transaction';

  /**
   * impelement index resource
   *
   * @return array of paymentTransactions
   */
  getAllPaymentTransactions(): Promise<CommercePaymentTransaction[]> {
    return this.get();
  }

  /**
   * implement retrieve resource
   *
   * @param paymentTransactionId paymentTransaction id to retrieve
   * @return object of the paymentTransaction entity
   */
  getPaymentTransactionById(paymentTransactionId: number): Promise<CommercePaymentTransaction> {
    return this.get(paymentTransactionId);
  }

  /**
   * implement create resource
   *
   * See commerce_payment_entity_property_info()
   * in sites/all/modules/commerce/modules/payment/commerce_payment.info.inc in Drupal 7
   * Make sure to set the setter callback property otherwise the API won't support writing
   * to the fields and will return an error.
   *
   * Property to add: 'setter callback' => 'entity_property_verbatim_set',
   * Objects to add this property: 'remote_id', 'amount', 'currency_code', 'message'
   *
   * After adding this property, you'll need to flush your caches on the site and/or run cron
   * and run updates for the changes to take effect.
   *
   * @param paymentTransaction payment transaction entity to create
   * @return created payment transaction details
   */
  createPaymentTransaction(paymentTransaction: Payment): Promise<CommercePaymentTransaction> {
    return this.post(paymentTransaction);
  }

  /**
   * implement update resource
   *
   * @param paymentTransaction payment transaction to update
   * @return updated payment transaction details
   */
  updatePaymentTransaction(paymentTransaction: {paymentTransactionId: number}): Promise<CommercePaymentTransaction> {
    return this.put(paymentTransaction, paymentTransaction.paymentTransactionId);
  }

  /**
   * implement delete resource
   *
   * @param paymentTransactionId payment transaction id to delete
   * @return array true if payment transaction deleted, otherwise return false array
   */
  deletePaymentTransaction(paymentTransactionId: number): Promise<boolean[]> {
    return this.delete(paymentTransactionId);
  }

}
