// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list:not(.item-radios) ion-item {
  border-right-color: var(--ion-color-dark);
  margin: 0px !important;
  box-shadow: none !important;
}
ion-list:not(.item-radios) ion-item .speakers {
  margin-inline-start: 15px;
}
ion-list:not(.item-radios) ion-item .speakers ion-badge {
  font-size: 10px;
}
ion-list:not(.item-radios) ion-item .track-icon-container {
  margin-inline-start: 10px;
}
ion-list:not(.item-radios) ion-item .track-icon-container .track-icon {
  width: 2.5rem;
  height: 2.5rem;
}

ion-item > ion-label.inline {
  margin-inline-start: 15px;
}

ion-item.favorite {
  border-right-color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/events/events.component.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;EACA,sBAAA;EACA,2BAAA;AACJ;AAAI;EACI,yBAAA;AAER;AADQ;EACI,eAAA;AAGZ;AAAI;EACI,yBAAA;AAER;AADQ;EACI,aAAA;EACA,cAAA;AAGZ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,4CAAA;AACJ","sourcesContent":["ion-list:not(.item-radios) ion-item {\n    border-right-color: var(--ion-color-dark);\n    margin: 0px !important;\n    box-shadow: none !important;\n    .speakers {\n        margin-inline-start: 15px;\n        ion-badge {\n            font-size: 10px;\n        }\n    }\n    .track-icon-container {\n        margin-inline-start: 10px;\n        .track-icon {\n            width: 2.5rem;\n            height: 2.5rem;\n        }\n    }\n}\n\nion-item>ion-label.inline {\n    margin-inline-start: 15px;\n}\n\nion-item.favorite {\n    border-right-color: var(--ion-color-primary);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
