// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list-header {
  align-items: center;
  flex-flow: column;
}
ion-list-header h3 {
  margin-top: 20px;
  margin-bottom: 5px;
}
ion-list-header ion-button {
  margin-top: 5px;
  margin-bottom: 20px;
}

.flex-column {
  flex-direction: column;
}

.identities {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  margin-block-start: 20px;
}
.identities ion-img {
  max-width: 90px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/home/home.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,iBAAA;AACF;AAAE;EACE,gBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,eAAA;EACA,mBAAA;AAEJ;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,qCAAA;EACA,kBAAA;EACA,wBAAA;AACF;AAAE;EACE,eAAA;EACA,cAAA;AAEJ","sourcesContent":["ion-list-header {\n  align-items: center;\n  flex-flow: column;\n  h3 {\n    margin-top:20px;\n    margin-bottom: 5px;\n  }\n  ion-button {\n    margin-top: 5px;\n    margin-bottom: 20px;\n  }\n}\n\n.flex-column {\n  flex-direction: column;\n}\n\n.identities {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  text-align: center;\n  margin-block-start: 20px;\n  ion-img {\n    max-width: 90px;\n    margin: 0 auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
