import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { Webform, CreateWebformEntity, WebformSubmission} from '../models';

/**
 * webform service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class WebformService extends MainService {
  entityType = 'webform';

  /**
   * impelement index resource
   *
   * @return array of webform nodes
   */
  getAllWebforms(): Promise<Webform[]> {
    return this.get();
  }

  /**
   * implement retrieve resource
   *
   * @param uuid uuid of the node to get
   * @return object of the webform entity
   */
  getWebformByUuid(uuid: string): Promise<Webform> {
    return this.get(uuid);
  }

  /**
   * implement create resource
   *
   * @param webform webform entity to create
   * @return created webform details
   */
  createWebform(webform: CreateWebformEntity): Promise<Webform> {
    return this.post(webform);
  }

  /**
   * implement update resource
   *
   * @param node node to update
   * @return updated webform details
   */
  updateWebform(node: {uuid: string}): Promise<Webform> {
    return this.put(node, node.uuid);
  }

  /**
   * implement delete resource
   *
   * @param uuid uuid of the node to delete
   * @return array true if node deleted, otherwise return false array
   */
  deleteWebform(uuid: string): Promise<boolean[]> {
    return this.delete(uuid);
  }

  /**
   * implement submissions resource
   *
   * @param uuid uuid of the webform
   * @return array of the webform submissions
   */
  getWebformSubmissionsByUuid(uuid: string): Promise<WebformSubmission[]> {
    return this.get(`${uuid}/submissions`);
  }


}
