import { Component, inject, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Platform, LoadingController, IonicModule, ModalController } from '@ionic/angular';
import { ConferenceEvent, Speaker } from 'src/app/models/conference-event.model';
import { FlagService } from 'src/app/services/drupal7/drupal7-services.module';
import { FlagAction } from 'src/app/services/drupal7/models';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Browser } from '@capacitor/browser';


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class SessionDetailsComponent implements OnInit {

  public platform = inject(Platform);
  private flagService = inject(FlagService);
  public loadingCtrl = inject(LoadingController);
  private modalCtrl = inject(ModalController);
  public storage = inject(StorageService);

  @Input() event: ConferenceEvent;
  speaker: Speaker;

  constructor() {}

  ngOnInit() {
  }

  checkRoomImage() {
    return this.event.floor_map?.src.includes('.svg') ? 'room-map' : '';
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  async toggle() {
    const newVal = await this.storage.toggleFavoriteEvent(this.event, this.event.favorited);
    this.event.favorited = newVal;
    this.flagEntity('flag_favorite_event', newVal);
  }

  async openExternalLink(url: string) {
    await Browser.open({ url });
  }

  async flagEntity(flagName: string, flagAction: boolean) {
    const action = flagAction === true ? 'flag' : 'unflag';
    const flagging: FlagAction = {
      flag_name: flagName.replace('flag_', ''),
      entity_id: this.event.nid,
      skip_permission_check: true,
      action,
      uid: 0
    };

    this.flagService.flag(flagging).then(data => {
    });
  }

  // async shareSession(session) {
  //   this.showLoading('Formatting the session data for you...');
  //   console.log('started share function');
  //   const options = {
  //     message: 'Here is session by ' + session.speaker + ' about ' + session.title + ' on '
  //     + session.day + ' at ' + session.time + ' in ' + session.room + '.',
  //     subject: 'SLC Asia 2023 Session - ' + session.title,
  //     url: 'https://slconferenceasia.com/node/' + session.nid,
  //   };
  //   if (this.platform.is('cordova')) {
  //   this.socialSharing.shareWithOptions(options)
  //     .then(res => {
  //       console.log('Shared: ', res);
  //       this.loadingCtrl.dismiss();
  //     })
  //     .catch(error => {
  //       console.log('Error: ', error);
  //       this.loadingCtrl.dismiss();
  //     });
  //   } else {
  //     this.loadingCtrl.dismiss();
  //     window.open('mailto:' + ' ' + '?subject=' + options.subject + '&body=' + options.message + ' ' + options.url);
  //   }
  // }

}
