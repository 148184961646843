import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DelegateRegistrationRecordLocator } from 'src/app/services/drupal7/models/node-types';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
})
export class FeedbackPage implements OnInit {
  @Input() delegate: FeedbackData;
  feedback = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    type: '',
    nid: 0,
    easy: '',
    heard: '',
    helpful: '',
    futureSessions: '',
    changes: '',
    comments: ''
  };

  constructor(
    private modalController: ModalController,
  ) {
  }

  ngOnInit() {
    if (this.delegate?.nid) {
      Object.keys(this.delegate).map(key => {this.feedback[key] = this.delegate[key]});
    }
  }

  cancelModal() {
    this.modalController.dismiss();
  }

  filter() {
    this.modalController.dismiss({
      feedback: this.feedback
    });
  }

}

export interface FeedbackData {
  first_name: string;
  last_name: string;
  email?: string;
  phone_number?: string;
  type?: string;
  nid: number;
  easy?: string;
  heard?: string;
  helpful?: string;
  futureSessions?: string;
  changes?: string;
  comments?: string;
}
