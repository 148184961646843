// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select,
ion-avatar {
  margin-inline-start: 15px;
}

ion-list.item-separate ion-item-divider {
  background: var(--ion-color-primary);
}
ion-list.item-separate ion-item-divider h2 {
  color: var(--ion-color-light);
}`, "",{"version":3,"sources":["webpack://./src/app/speakers/speakers.component.scss"],"names":[],"mappings":"AAAA;;EAEI,yBAAA;AACJ;;AAEA;EACI,oCAAA;AACJ;AAAI;EACI,6BAAA;AAER","sourcesContent":["ion-select,\nion-avatar {\n    margin-inline-start: 15px;\n}\n\nion-list.item-separate ion-item-divider {\n    background: var(--ion-color-primary);\n    h2 {\n        color: var(--ion-color-light);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
