// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-footer {
  box-shadow: 0px 2px 20px 6px rgba(111, 111, 111, 0.168627451);
}

.ios ion-textarea {
  margin: 10px 0;
}

textarea {
  --border: 1px solid #ccc;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZlZWRiYWNrLnBhZ2Uuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLDZEQUFBO0FBQ0o7O0FBRUE7RUFDSSxjQUFBO0FBQ0o7O0FBRUE7RUFDSSx3QkFBQTtBQUNKIiwiZmlsZSI6ImZlZWRiYWNrLnBhZ2Uuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1mb290ZXIge1xuICAgIGJveC1zaGFkb3c6IDBweCAycHggMjBweCA2cHggIzZmNmY2ZjJiO1xufVxuXG4uaW9zIGlvbi10ZXh0YXJlYSB7XG4gICAgbWFyZ2luOiAxMHB4IDA7XG59XG5cbnRleHRhcmVhIHtcbiAgICAtLWJvcmRlcjogMXB4IHNvbGlkICNjY2M7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/modals/feedback/feedback.page.scss"],"names":[],"mappings":"AAAA;EACI,6DAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;AACA,wfAAwf","sourcesContent":["ion-footer {\n    box-shadow: 0px 2px 20px 6px #6f6f6f2b;\n}\n\n.ios ion-textarea {\n    margin: 10px 0;\n}\n\ntextarea {\n    --border: 1px solid #ccc;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
