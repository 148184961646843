import { Component } from '@angular/core';
import { FirebaseCloudMessagesService } from './services/firebase-cloud-messages.service';
import { StorageService } from './services/storage.service';
import { Capacitor } from '@capacitor/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class AppComponent {
  constructor(private storage: StorageService,
    private fcmService: FirebaseCloudMessagesService) {
    this.init();
  }

  async init() {
    await this.storage.init();
    // Trigger the push setup
    if (Capacitor.getPlatform() !== 'web') {
      this.fcmService.initPush();
    }
  }


}
