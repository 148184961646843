import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { NodeEntity } from '../models';

/**
 * node service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class CommerceProductReferenceService extends MainService {
  entityType = 'commerce_product_reference';

  /**
   * impelement index resource
   *
   * @return array of nodes with product displays
   */
  getAllProductReferences(): Promise<NodeEntity[]> {
    return this.get();
  }

  /**
   * implement retrieve resource
   *
   * @param nid node id to retrieve
   * @return object of the product display for the nid
   */
  getProductReferenceByNid(nid: number): Promise<NodeEntity> {
    return this.get(nid);
  }


}
